import React from 'react';
import Row from 'react-bootstrap/Row';
import initialProjects from '../initialProjects';
import Header from '../components/Header';
import SingleItem from './SingleItem';
import { Col, Container } from 'react-bootstrap';

const bannerImg = '../images/banner.jpg';

export default function AllProducts() {
  //   const { id } = useParams();
  //   const { t } = useTranslation();
  const productsByCategoryId = [];

  initialProjects.products.forEach((category) => {
    productsByCategoryId[category.categoryId] = {
      categoryName: category.categoryName,
      kuName: category.kuName,
      color: category.color,
      products: category.productCategory.map((product) => ({
        productId: product._id,
        arName: product.ArName,
        kuName: product.kuName,
        price: product.price,
        dinar: product.dinar,
        project_img: product.project_img,
      })),
    };
  });

  //   console.log(productsByCategoryId?.map((product, index) => product.categoryName));

  if (!productsByCategoryId) {
    return (
      <>
        <Header />
        <div className="container-fluid  product-section ">
          <Row className="category_name_all"></Row>
          <Row className="product-page-non w-100  p-5">
            <h2 className="h2-title">هیچ جۆرە ساردەمەنیەک نەدۆزرایەوە</h2>
          </Row>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="product-section-all">
        <div className="category_name_all ">
          <h1 className="category-title-all">Menu - مینۆ</h1>
        </div>

        {/* {productsByCategoryId?.map((product, index) => (
        <div key={index}>
          <div className="categoryTitle ">
            <h1 className="categoryAllTitle">
              {product.categoryName} - {product.kuName}
            </h1>
          </div>

          <div className="container-fluid mt-2 w-100">
            <Row>
              {product &&
                product.products?.map((item, index) => (
                  <Col key={index} sm={6} md={4} lg={3} className="p-2">
                    <SingleItem data={item} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      ))} */}
      </div>
      <Container>
        <Row>
          <Col>
            <img className="img-product" src={bannerImg} alt={bannerImg} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
