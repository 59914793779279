import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export default function Product(props) {
  const { t } = useTranslation();
  const { product } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div key={product._id} className="box  w-100 ">
      <Link
        className="text-color"
        // to={`/product/${product._id}`}
        to={{
          pathname: `/products/${product._id}`,
          state: { product: product },
        }}
        // onClick={scrollToTop}
      >
        <img
          className="img-section"
          src={product.image}
          alt={product.projectName}
        />
        <div className="service-name">{t(`${product.name}`)}</div>
        <div className="service-name">{t(`${product.kuName}`)}</div>
      </Link>
    </div>
  );
}
