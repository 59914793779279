import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import data from '../data';
import Product from '../components/Product';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#6b391e',
        top: '-20px',
        right: '3px',
        paddingTop: '6px',
        width: '40px',
        height: '30px',
        borderRadius: '10px',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        // display: 'block',
        background: '#6b391e',
        top: '-20px',
        left: '3px',
        width: '40px',
        height: '30px',
        borderRadius: '10px',
        paddingTop: '6px',
      }}
      onClick={onClick}
    />
  );
}

export default function Work() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    lazyLoad: true,
    // autoplay: true,
    // autoplaySpeed: 1000,
    // cssEase: "linear",
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // prevArrow: (
    //   <button
    //     style={{ position: 'absolute', top: '5px', left: '10px', zIndex: 1 }}
    //   >
    //     Previous
    //   </button>
    // ),
    // nextArrow: (
    //   <button
    //     style={{ position: 'absolute', top: '5px', right: '10px', zIndex: 1 }}
    //   >
    //     Next
    //   </button>
    // ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const { t } = useTranslation();

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <>
      {/* <div className="container-fluid  shipping-info ">
        <Row>
          <h3 className="h1-title">{t('our_category')}</h3>
        </Row>
        <Row>
          {data.products.map((product) => (
            <Col
              key={product._id}
              // className="mb-2 col-sm-4 col-md-3 col-lg-2"
              xs={4}
              sm={3}
              md={3}
              lg={2}
            >
              <Product product={product}></Product>
            </Col>
          ))}
        </Row>
      </div> */}
      <div className="container-fluid carousel-slide">
        <Row>
          <h3 className="h1-title">{t('our_category')}</h3>
        </Row>
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {data.products.map((product) => (
            <div key={product._id}>
              <Product product={product}></Product>
            </div>
          ))}
        </Slider>
        {/* <div style={{ textAlign: 'center' }}>
          <h1 className="button" onClick={previous}>
            <i onClick={previous} class="fa fa-chevron-circle-left" fontSize={32}></i>
          </h1>
          <button onClick={next}>
          <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
          </button>
        </div> */}
      </div>
    </>
  );
}
