import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    // <button onClick={() => navigate(-1)}>
      <i
        onClick={() => navigate(-1)}
        className="arrow-header fa fa-arrow-left"
        aria-hidden="true"
      ></i>
    // </button>
  );
};

export default BackButton;
