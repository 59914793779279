const data = {
  products: [
    {
      _id: '1',
      name: 'Ice Cream',
      kuName: 'ئایس کرێم',
      image: '/images/ice-cream/icecream.png',
      imageBranchf: ['Business Website', 'Personal Website', 'Ecommerce Store'],
      description: 'Ice Cream',
    },
    {
      _id: '2',
      name: 'Fruit Splashe',
      kuName: 'ئەزبەری میوە',
      image: '/images/splash/splash.jpg',
      description: 'Fruit Splashe',
    },
    {
      _id: '3',
      name: 'Nutella',
      kuName: 'نوتێلا',
      image: '/images/nutella/nutella.png',
      description: 'Nutella',
    },
    {
      _id: '4',
      name: 'Fresh Juice',
      kuName: 'شەربەتی سروشتی',
      image: '/images/juice/fjuice.png',
      description: 'Fresh Juice',
    },
    {
      _id: '5',
      name: 'Cake',
      kuName: 'کێک',
      image: '/images/cake/cake.png',
      description: 'Cake',
    },
    {
      _id: '6',
      name: 'Mahlabi',
      kuName: 'محەلەبی',
      image: '/images/mhalabi/mhalabi.png',
      description: 'Paqlawa',
    },
    {
      _id: '7',
      name: 'Kunafa',
      kuName: 'کنافە',
      image: '/images/kunafa/kunafamain.png',
      description: 'Paqlawa',
    },
    {
      _id: '8',
      name: 'Custard & Mhalabi',
      kuName: 'کاستەر و محەلەبی',
      image: '/images/paqlawa/paqlawa.png',
      description: 'Paqlawa',
    },
  ],
};
export default data;
