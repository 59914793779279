import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'react-bootstrap';
import Header from '../components/Header';
import initialProjects from '../initialProjects';
import Category from '../components/Category';
import { t } from 'i18next';
import Projects from '../components/Projects';
import { useParams } from 'react-router-dom';
import AllProducts from '../components/AllProducts';

export default function HomeScreen() {
  const { t } = useTranslation();

  const [mydata, setdata] = useState(initialProjects);

  function reveal() {
    var reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  window.addEventListener('scroll', reveal);

  return (
    <div className="body_section">
      <Header />

      <div
        // id="our_products reveal"
        // className="container-fluid d-flex"
      >
        <AllProducts />
      </div>

      {/* <Projects /> */}
    </div>
  );
}
