// import React, { useEffect, useState } from 'react';
// import Row from 'react-bootstrap/Row';
// import SingleCard from '../components/SngleCard';
// import initialProjects from '../initialProjects';
// import Header from '../components/Header';
// import { useParams } from 'react-router-dom';
// import { Col } from 'react-bootstrap';

// export default function ProjectScreen() {
//   const { id } = useParams();

//   const [pid, setPid] = useState(id);

//   const [allProducts, setAllProducts] = useState([
//     initialProjects.products.find((x) => x.categoryId === id),
//   ]);

//   useEffect(() => {
//     localStorage.setItem('productID', JSON.stringify(id));
//     setPid(id);
//     setAllProducts(
//       initialProjects.products.find(
//         (x) => x.categoryId === JSON.parse(localStorage.getItem('productID'))
//       )
//     );
//   }, [id, pid]);

//   console.log('id', id, 'pid', localStorage.getItem('productID'));
//   // const allProducts = initialProjects.products.find(
//   //   (x) => x.categoryId === JSON.parse(localStorage.getItem('productID'))
//   // );

//   if (!allProducts) {
//     return (
//       <>
//         <Header />
//         <div className="container-fluid  product-section ">
//           <Row className="category_noname "></Row>
//           <Row className="product-page-non w-100  p-5">
//             <h2 className="h2-title">هیچ جۆرە ساردەمەنیەک نەدۆزرایەوە</h2>
//           </Row>
//         </div>
//       </>
//     );
//   }

//   return (
//     <>
//       <Header />
//       <div className="container-fluid  product-section  ">
//         <Row
//           className="category_name "
//           style={{ backgroundColor: allProducts.bgcolor }}
//         >
//           <h1 className="category-title" style={{ color: allProducts.color }}>
//             {allProducts.categoryName} - {allProducts.kuName}
//           </h1>
//         </Row>
//         <div className="container-fluid mt-2 w-100">
//           <Row>
//             {allProducts.productCategory?.map((product, index) => (
//               <Col key={index} sm={6} md={4} lg={3} className="p-2">
//                 <SingleCard data={product} />
//               </Col>

//             ))}
//           </Row>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SingleCard from '../components/SingleCard';
import initialProjects from '../initialProjects';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';

export default function ProjectScreen() {
  const { id } = useParams();

  const [allProducts, setAllProducts] = useState(() => {
    // Find the category with the given id and return its productCategory
    const category = initialProjects.products.find(
      (product) => product.categoryId === id
    );
    return category ? category.productCategory : [];
  });

  useEffect(() => {
    const category = initialProjects.products.find(
      (product) => product.categoryId === id
    );
    setAllProducts(category ? category.productCategory : []);
  }, [id]);

  if (!allProducts.length) {
    return (
      <>
        <Header />
        <div className="container-fluid product-section">
          <Row className="category_noname"></Row>
          <Row className="product-page-non w-100 p-5">
            <h2 className="h2-title">هیچ جۆرە ساردەمەنیەک نەدۆزرایەوە</h2>
          </Row>
        </div>
      </>
    );
  }

  const categoryInfo = initialProjects.products.find(
    (product) => product.categoryId === id
  );

  // console.log(categoryInfo);

  return (
    <>
      <Header />
      <div className="container-fluid product-section">
        <Row
          className="category_name"
          style={{
            backgroundColor: categoryInfo.bgcolor,
          }}
        >
          <h1 className="category-title" style={{ color: categoryInfo.color }}>
            {categoryInfo.categoryName} - {categoryInfo.kuName}
          </h1>
        </Row>

        <div className="container-fluid mt-2 w-100">
          <Row>
            {allProducts.map((product, index) => (
              <Col key={index} sm={6} md={4} lg={3} className="p-2">
                <SingleCard data={product} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}
