import React from 'react';
import { useParams } from 'react-router-dom';

// const bannerImage = '../images/glossycode.png';

const SingleItem = ({ data }) => {
  const { id } = useParams();

  //   const product = initialProjects.products.find((x) => x.categoryId === id);

  return (
    // <div className="position-relative">
    //   <Link
    //     className="text-color "
    //     // to={`/product/${product._id}`}
    //     to={{
    //       pathname: `/product/${data._id}`,
    //       state: { data: data },
    //     }}
    //     // onClick={scrollToTop}
    //   >
    //     <Card className="box-product w-100 ">
    //       <img
    //         className="img-product"
    //         src={data.project_img}
    //         alt={data.project_img}
    //       />
    //       <div className="title-name">{data.ArName}</div>
    //       <div className="title-name">{data.kuName}</div>
    //     </Card>
    //     <div
    //       className="img-over position-absolute"
    //       style={{ backgroundColor: product.bgcolor, color: product.color }}
    //     >
    //       <h3 className="price-title">{data.price}</h3>
    //       <p>{data.dinar}</p>
    //     </div>
    //   </Link>
    // </div>
    <>
      {/* <div>{data.categoryName}</div> */}
      <div className="card ">
        <img src={data.project_img} className="card-img-top" alt="..." />
        <div className="card-body">
          <div className="text-section">
            <p className="card-title fw-bold">{data?.ArName}</p>
            <p className="card-text">{data.kuName}</p>
            <div className="cta-section">
              <div className="price-title ">
                {data.price}
                <div className="price-currency ">{data.dinar}</div>
              </div>

              {/* <a href="#" class="btn btn-light">Buy Now</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleItem;
