const initialProjects = {
  products: [
    {
      categoryId: '1',
      categoryName: 'Ice Cream',
      kuName: 'ئایس کرێم',
      bgcolor: '#E7708C',
      color: '#000000',
      productCategory: [
        {
          _id: '1',
          ArName: 'دۆندرمەی سادە',
          kuName: 'ئایـس کریم سادە',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/sada.png',
        },
        {
          _id: '2',
          ArName: 'ئایس کریم مشكل بە فستق',
          kuName: 'دۆندرمەی مشكل بە فستق',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/mushakalfistaq2.png',
        },
        {
          _id: '3',
          ArName: 'ئایس کریم بالنستلة',
          kuName: 'دۆندرمەیبە نەستەلە',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/nastala.png',
        },
        {
          _id: '4',
          ArName: 'ئایس کریم بالفستق خالیة من السکر',
          kuName: 'دۆندرمەی بێ شەکر بە فستق',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/noshugarFistaq.png',
        },
        {
          _id: '5',
          ArName: 'ئایس کریم خالیة من السکر',
          kuName: 'دۆندرمەی بێ شەکر سادە',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/noshugarsada.png',
        },
        {
          _id: '6',
          ArName: 'ئایس کریم حلیب و فستق',
          kuName: 'دۆندرمەی شیر وفستق',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/milkfistaq.png',
        },
        {
          _id: '7',
          ArName: 'ئایس کریم خالیة من السکر',
          kuName: 'دۆندرمەی بێ شەکر سادە',
          price: '2500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/noshugarsada2.png',
        },
        {
          _id: '8',
          ArName: 'VIP ئایس کریم بدون سکر بلفستق',
          kuName: 'VIP دۆندرمەی بێ شەکر بە فستق',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/nosugarvipfistaq.png',
        },
        {
          _id: '9',
          ArName: 'VIP ئایس کریم بالفستق',
          kuName: 'VIP دۆندرمەی بە فستق',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/vipfistaq.png',
        },
        {
          _id: '10',
          ArName: 'ئایس کریم مشكل بە فستق',
          kuName: 'دۆندرمەی مشكل بە فستق',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/mushakalfistaq.png',
        },
        {
          _id: '11',
          ArName: 'VIP ئایس کریم بالفستق',
          kuName: 'VIP دۆندرمەی بە فستق',
          price: '2500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/cvipfistaq.png',
        },
        {
          _id: '12',
          ArName: 'VIP ئایس کریم سادە',
          kuName: 'VIP دۆندرمەی سادە',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/cvipsada.png',
        },
        {
          _id: '13',
          ArName: 'ئایس کریم کوکتیل هولیر',
          kuName: 'دۆندرمەی کۆکتێل هەولێر',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/koktelhawler.png',
        },
        {
          _id: '14',
          ArName: 'VIP ئایس کریم حلیب بالفستق',
          kuName: 'VIP دۆندرمەی شیر فستەق',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/vipmilkfistaq.png',
        },
        {
          _id: '15',
          ArName: ' سباگیتی حلیب موز',
          kuName: 'سپاگێتی شیــــر مۆز',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/spagetimilkban.png',
        },
        {
          _id: '16',
          ArName: ' سباگیتی حلیب نوتیلا',
          kuName: 'سپاگێتی شیــــر نوتێلا',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/spagetimilknut.png',
        },
        {
          _id: '17',
          ArName: ' سباگیتی حلیب فرولة',
          kuName: 'سپاگێتی شیــــر فراولە',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/spagetimilkfra.png',
        },
        {
          _id: '18',
          ArName: ' ایس کریم حلیب ماعز',
          kuName: ' دۆندرمە شیر بزن',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/ice-cream/shirbzn.png',
        },
        {
          _id: '19',
          ArName: ' ایس کریم بالفستق و نستلة',
          kuName: ' دۆندرمە بە فستق و نەستەلە',
          price: '2500',
          dinar: 'دینار',
          project_img: '/images/ice-cream/fistaqnastala.png',
        },
      ],
    },
    {
      categoryId: '2',
      categoryName: 'Fresh Splashe',
      kuName: 'ئەزبەری میوە',
      bgcolor: '#D21D5A',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          ArName: 'ازبری فراولـة',
          kuName: 'ئەزبەری فڕاولە',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/frawla.png',
        },
        {
          _id: '2',
          ArName: 'ازبری کۆکتێل',
          kuName: 'ئەزبەری کوکتیل',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/koktel.png',
        },
        {
          _id: '3',
          ArName: 'ازبری رمان',
          kuName: 'ئەزبەری هەنار',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/hanar.png',
        },
        {
          _id: '4',
          ArName: 'ازبری حلیب موز',
          kuName: 'ئەزبەری شیر مۆز',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/milkban.png',
        },
        {
          _id: '5',
          ArName: 'ازبری حلیب بالفستق',
          kuName: 'ئەزبەری شیر و فستق',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/milkfistaq.png',
        },
        {
          _id: '6',
          ArName: 'ازبری تمرهندي',
          kuName: 'ئەزبەری تەمەرهیندی ',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/tamar.png',
        },
        {
          _id: '7',
          ArName: 'ازبری برتقال',
          kuName: 'ئەزبەری پرتەقاڵ ',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/orange.png',
        },
        {
          _id: '8',
          ArName: 'ازبری زبیب',
          kuName: 'ئەزبەری مێوژ ',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/mewzh.png',
        },
        {
          _id: '9',
          ArName: 'ازبری بلوبیري',
          kuName: 'ئەزبەری بلوبێری ',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/splash/blue.png',
        },
      ],
    },
    {
      categoryId: '3',
      categoryName: 'Nutella',
      kuName: 'نوتێلا',
      bgcolor: '#532A25',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          ArName: 'ایس کریم نوتیللا و لوتوس',
          kuName: 'دۆندرمە نۆتێللا و لۆتۆس',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/lotos.png',
        },
        {
          _id: '2',
          ArName: 'ایس کریم نوتیللا و  فروتي',
          kuName: 'دۆندرمە نۆتێللا و  فروتی',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/fruty.png',
        },
        {
          _id: '3',
          ArName: 'ایس کریم نوتیللا و  جرزات',
          kuName: 'دۆندرمە نۆتێللا و  چەرەس',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/nutella/charas.png',
        },
        {
          _id: '4',
          ArName: 'ایس کریم نوتیللا و  کیندر',
          kuName: 'دۆندرمە نۆتێللا و  کیندەر',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/kinder.png',
        },
        {
          _id: '5',
          ArName: 'ایس کریم VIP لوتس اربیل',
          kuName: 'دۆندرمەVIP  لۆتس هەولێر',
          price: '5000',
          dinar: 'دینار',
          project_img: '/images/nutella/viplots.png',
        },
        {
          _id: '6',
          ArName: 'ایس کریم نوتیللا کیت کات',
          kuName: 'دۆندرمە نۆتێللا و کیت کات',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/kitkat.png',
        },
        {
          _id: '7',
          ArName: 'ایس کریم نوتیللا کیت کات',
          kuName: 'ایس کریم نوتیلا شیک',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/nutellashake.png',
        },
        {
          _id: '8',
          ArName: '',
          kuName: 'شیک لۆتوس',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/shiklotos.png',
        },

        {
          _id: '9',
          ArName: '',
          kuName: 'شیک ئۆریو',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/nutellaoreo.png',
        },

        {
          _id: '10',
          ArName: 'وفل ببل',
          kuName: 'وەفلی بەبل',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/waflbubble.png',
        },
        {
          _id: '11',
          ArName: ' وافل بالموز و نوتیللا و فراولە',
          kuName: 'وافل بە مۆز و نوتێلاو فراولە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/nutella/wafl.png',
        },
        {
          _id: '12',
          ArName: 'VIP وافل',
          kuName: 'VIP وەفل',
          price: '5000',
          dinar: 'دینار',
          project_img: '/images/nutella/waflvip.png',
        },
        {
          _id: '13',
          ArName: 'نوتیلا جرز کبیر',
          kuName: 'نوتێلا چەرەز گەورە',
          price: '7000',
          dinar: 'دینار',
          project_img: '/images/nutella/charazgawra.png',
        },
      ],
    },

    {
      categoryId: '4',
      categoryName: 'Fresh Juice',
      kuName: 'شەربەتی سروشتی',
      bgcolor: '#F9D43B',
      color: '#000000',
      productCategory: [
        {
          _id: '1',
          ArName: '',
          kuName: 'ئەڤۆگادۆ',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/avog.png',
        },
        {
          _id: '2',
          ArName: '',
          kuName: 'ئەنەناس',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/anan.png',
        },
        {
          _id: '3',
          ArName: 'عـصیـر کـوکـتیل زبیـب',
          kuName: 'شەربەتی کۆکتێل مێوژ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/mewzh.png',
        },
        {
          _id: '4',
          ArName: 'عـصیـر طبیعی گیلاس',
          kuName: 'شەربەتی سروشتی گێلاس',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/gelas.png',
        },
        {
          _id: '5',
          ArName: 'عـصیـر طبیعی برتقال',
          kuName: 'شەربەتی سروشتی پرتەقاڵ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/orange.png',
        },
        {
          _id: '6',
          ArName: 'رمان طبیعي',
          kuName: 'بەبلی فراولە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/hanar.png',
        },
        {
          _id: '7',
          ArName: 'عـصیـر کـوکـتیل طبیعي',
          kuName: 'شەربەتی کۆکتێل سروشتی',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/koktel.png',
        },
        {
          _id: '8',
          ArName: 'عـصیـر طبــیعی بطیخ',
          kuName: 'شەربەتی سروشتی گندۆرە',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/gndora.png',
        },
        {
          _id: '9',
          ArName: 'عـصیـر طبــیعی رمان',
          kuName: 'شەربەتی سروشتی هەنار',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/hanarjuice.png',
        },
        {
          _id: '10',
          ArName: 'عـصیـر طبــیعی موز و نوتیلا',
          kuName: 'شەربەتی سروشتی مۆزو نوتێلا',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/nutelaban.png',
        },
        {
          _id: '11',
          ArName: 'عـصیـر طبــیعی لیمو',
          kuName: 'شەربەتی سروشتی لیمۆ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/lemo.png',
        },
        {
          _id: '12',
          ArName: 'عـصیـر طبــیعی فراولة',
          kuName: 'شەربەتی سروشتی فراولە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/juice/frawla.png',
        },
        {
          _id: '13',
          ArName: 'عـصیـر طبــیعی موز',
          kuName: 'شەربەتی سروشتی مۆز',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/bana.png',
        },
        {
          _id: '13',
          ArName: 'عـصیـر طبــیعی تفاح',
          kuName: 'شەربەتی سروشتی سێو',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/juice/apple.png',
        },
      ],
    },

    {
      categoryId: '5',
      categoryName: 'Cake',
      kuName: 'کێک',
      bgcolor: '#A97D52',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          ArName: 'کیک کبیر بالگوز هندي',
          kuName: 'کێکی گەورە بە گوێزی هندی',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/hindi.png',
        },
        {
          _id: '2',
          ArName: 'کیک موز و جکلیت بالفستق',
          kuName: ' کێکی مۆز و چکلێت بە فستق',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/chukletfstaq.png',
        },
        {
          _id: '3',
          ArName: 'عکیک کبیر بالجکلیت',
          kuName: 'کێکی گەورو بە چکلێت',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/chuklet.png',
        },
        {
          _id: '4',
          ArName: 'عکیک موز و بندق',
          kuName: 'شکێکی مۆز و بندق',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/bananbnduq.png',
        },
        {
          _id: '5',
          ArName: 'کیک کبیر بالبندق',
          kuName: 'شکێکی گەورە بە بندق ',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/bnduq.png',
        },
        {
          _id: '6',
          ArName: 'کیک کبیر بالفستق',
          kuName: 'کێکی گەورە بە فستق ',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/fstaq.png',
        },
        {
          _id: '7',
          ArName: 'کیک کبیر بروفتوب',
          kuName: 'کێکی گەورە پڕۆفتۆپ',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/proftop.png',
        },
        {
          _id: '8',
          ArName: 'کیک کبیر بالابیض کریم',
          kuName: 'کێکی گەورە بەکرێمی سپی ',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/cream.png',
        },
        {
          _id: '9',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c1.png',
        },
        {
          _id: '10',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c2.png',
        },
        {
          _id: '11',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c3.png',
        },
        {
          _id: '12',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c4.png',
        },
        {
          _id: '13',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c5.png',
        },
        {
          _id: '13',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c6.png',
        },
        {
          _id: '14',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c7.png',
        },
        {
          _id: '15',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c8.png',
        },
        {
          _id: '16',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c9.png',
        },
        {
          _id: '17',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c10.png',
        },
        {
          _id: '18',
          ArName: '',
          kuName: 'کێکی گەورە',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/cake/c11.png',
        },

        {
          _id: '19',
          ArName: 'کیک ئیسرا',
          kuName: 'کێکی ئیسرا',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/cake/isra.png',
        },

        {
          _id: '20',
          ArName: 'کیک بانا کوندا',
          kuName: 'کێکی پانا کۆندا',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/cake/konda.png',
        },
        {
          _id: '21',
          ArName: 'کیک ترالیجە',
          kuName: 'کێکی ترالیچە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/cake/tralicha.png',
        },
      ],
    },
    {
      categoryId: '6',
      categoryName: 'Mahlabi',
      kuName: 'محەلەبی',
      bgcolor: '#ABE2EF',
      color: '#000000',
      productCategory: [
        {
          _id: '1',
          ArName: 'محلبي کیندر',
          kuName: 'محلبی کیندەر',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/mhalabi/kinder.png',
        },
        {
          _id: '2',
          ArName: 'محلبی کیت کات',
          kuName: 'محلبی کیت کات',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/mhalabi/kitkat.png',
        },

        {
          _id: '3',
          ArName: 'خشخش',
          kuName: 'خشخش',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/mhalabi/xshxsh.png',
        },
        {
          _id: '4',
          ArName: 'محلبي ئوریو',
          kuName: 'محلبی ئۆریۆ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/mhalabi/oreo.png',
        },
      ],
    },

    {
      categoryId: '7',
      categoryName: 'Kunafa',
      kuName: 'کنافە',
      bgcolor: '#90A87C',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          ArName: 'کونافة یدویة المحلیة',
          kuName: 'کونافە دەستکردی خۆماڵی',
          price: '4000',
          dinar: 'دینار',
          project_img: '/images/kunafa/kunafa.png',
        },
        {
          _id: '2',
          ArName: 'باقلاوة میدیا (٣) قطعة',
          kuName: 'پاقڵاوەی میدیا (٣) دانە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/kunafa/media.png',
        },

        {
          _id: '3',
          ArName: 'باقلاوە هاوج دیلمیة',
          kuName: 'پاقڵاوەی هاوچ دیلمە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/kunafa/hawch.png',
        },
        {
          _id: '4',
          ArName: 'باقلاوة هاوج دیلمیة و ایس کریم حلیب ماعز',
          kuName: 'پاقڵاوەی هاوچ دیلمە و دۆندرمەی شیر بزن',
          price: '5000',
          dinar: 'دینار',
          project_img: '/images/kunafa/milkhawch.png',
        },
        {
          _id: '5',
          ArName: 'کونافة بالحلیب ماعز',
          kuName: 'کونافە بە شیر بزن',
          price: '6000',
          dinar: 'دینار',
          project_img: '/images/kunafa/kunafamilk.png',
        },
      ],
    },

    {
      categoryId: '8',
      categoryName: 'Custard & Mhalabi',
      kuName: 'کاستەر و محەلەبی',
      bgcolor: '#774652',
      color: '#fff',
      productCategory: [
        {
          _id: '1',
          ArName: 'کاستەر بالبقلاوە و فستق',
          kuName: 'کاستەر بە پاقڵاوەو فستق',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/paqlawafstaq.png',
        },
        {
          _id: '2',
          ArName: 'محەلەبی بالبقلاوە و فستق ',
          kuName: 'محەلەبی بە پاقڵاوەو فستق ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/mhalabipaqlawa.png',
        },
        {
          _id: '3',
          ArName: 'محلبي سوتلاج',
          kuName: 'محەلبی سوتلاچ',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/sutlaj.png',
        },

        {
          _id: '4',
          ArName: 'کاستر بالفستق',
          kuName: 'کاستەر بە فستق',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/paqlawa/kastarfstaq.png',
        },
        {
          _id: '5',
          ArName: 'محلبی بالفستق',
          kuName: 'محەلەبی بە فستق',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/paqlawa/mhalabifstaq.png',
        },
        {
          _id: '6',
          ArName: 'کاستر سادە',
          kuName: 'کاستەر سادە',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/kastarsada.png',
        },
        {
          _id: '7',
          ArName: 'محلبي بالجوز',
          kuName: 'محەلەبی بە گوێز',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/paqlawa/mhalabiguez.png',
        },
        {
          _id: '8',
          ArName: 'محلبي سادە',
          kuName: 'محەلەبی سادە',
          price: '1000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/mhalabisada.png',
        },
        {
          _id: '9',
          ArName: 'کاستر بالجوز',
          kuName: 'کاستەر بە گوێز',
          price: '1500',
          dinar: 'دینار',
          project_img: '/images/paqlawa/kastarguez.png',
        },
        {
          _id: '10',
          ArName: 'ذرة حجم وسط',
          kuName: 'گەنمە شامی ناوەند',
          price: '2000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/nawand.png',
        },
        {
          _id: '11',
          ArName: 'ذرة حجم کبیر',
          kuName: 'گەنمە شامی گەورە',
          price: '3000',
          dinar: 'دینار',
          project_img: '/images/paqlawa/gawra.png',
        },
      ],
    },
  ],
};

export default initialProjects;
