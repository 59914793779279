import React from 'react';
import { useTranslation } from 'react-i18next';
import Work from './Work';
import { Link } from 'react-router-dom';
import BackButton from './BackButton';

export default function Header() {
  const { t } = useTranslation();
  const headerImg = '../images/logo.png';
  return (
    <div className=" shipping-info ">
      <div className=" arrow-div ">
        <BackButton />
        <Link
          className="text-color"
          to={`/`}

          // onClick={scrollToTop}
        >
          <i className="title-header fa fa-home" aria-hidden="true"></i>
          {/* <div className="title-header "><fa fa-bar>Menu</fa></div> */}
        </Link>
      </div>
      <div className="img-logo">
        <img className="bg-image" src={headerImg} alt={headerImg}></img>
      </div>
      <Work />
    </div>
  );
}
